@font-face {
    font-family: 'Gill Sans';
    src: url('../../../fonts/GillSans-LightItalic.eot');
    src: url('../../../fonts/GillSans-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/GillSans-LightItalic.woff2') format('woff2'),
        url('../../../fonts/GillSans-LightItalic.woff') format('woff'),
        url('../../../fonts/GillSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('../../../fonts/GillSans-Bold.eot');
    src: url('../../../fonts/GillSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/GillSans-Bold.woff2') format('woff2'),
        url('../../../fonts/GillSans-Bold.woff') format('woff'),
        url('../../../fonts/GillSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('../../../fonts/GillSans-Italic.eot');
    src: url('../../../fonts/GillSans-Italic.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/GillSans-Italic.woff2') format('woff2'),
        url('../../../fonts/GillSans-Italic.woff') format('woff'),
        url('../../../fonts/GillSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('../../../fonts/GillSans-Heavy.eot');
    src: url('../../../fonts/GillSans-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/GillSans-Heavy.woff2') format('woff2'),
        url('../../../fonts/GillSans-Heavy.woff') format('woff'),
        url('../../../fonts/GillSans-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('../../../fonts/GillSans-HeavyItalic.eot');
    src: url('../../../fonts/GillSans-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/GillSans-HeavyItalic.woff2') format('woff2'),
        url('../../../fonts/GillSans-HeavyItalic.woff') format('woff'),
        url('../../../fonts/GillSans-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('../../../fonts/GillSans-BoldItalic.eot');
    src: url('../../../fonts/GillSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/GillSans-BoldItalic.woff2') format('woff2'),
        url('../../../fonts/GillSans-BoldItalic.woff') format('woff'),
        url('../../../fonts/GillSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('../../../fonts/GillSans.eot');
    src: url('../../../fonts/GillSans.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/GillSans.woff2') format('woff2'),
        url('../../../fonts/GillSans.woff') format('woff'),
        url('../../../fonts/GillSans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('../../../fonts/GillSans-MediumItalic.eot');
    src: url('../../../fonts/GillSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/GillSans-MediumItalic.woff2') format('woff2'),
        url('../../../fonts/GillSans-MediumItalic.woff') format('woff'),
        url('../../../fonts/GillSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('../../../fonts/GillSans-Medium.eot');
    src: url('../../../fonts/GillSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/GillSans-Medium.woff2') format('woff2'),
        url('../../../fonts/GillSans-Medium.woff') format('woff'),
        url('../../../fonts/GillSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('../../../fonts/GillSans-Light.eot');
    src: url('../../../fonts/GillSans-Light.eot?#iefix') format('embedded-opentype'),
        url('../../../fonts/GillSans-Light.woff2') format('woff2'),
        url('../../../fonts/GillSans-Light.woff') format('woff'),
        url('../../../fonts/GillSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
